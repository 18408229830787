<template>
  <div class="relative f-col" data-test-id="product-card">
    <product-card-gallery
      :fetchpriority
      :items="data.gallery"
      :lazy
      :name="data.name"
      show-secondary-media
      @click-gallery-item="navigateToPDP"
    />
    <div class="p-2 space-y-1" :class="{ relative: $feature.enableProductCardGalleryCarousel }">
      <div class="line-clamp-1 eyebrow" data-test-id="product-card-badge" style="height: 0.96rem">
        {{ data.eyebrow?.label }}
      </div>
      <base-link
        class="body-2 line-clamp-2"
        data-test-id="product-card-title"
        :to="url"
        @click.capture="navigateToPDP"
      >
        <span class="absolute-0" />
        {{ data.name }}
      </base-link>
      <div v-if="$feature.showShortDescriptionInProductList && data.shortDescription" class="body-4 text-grey-30">
        {{ data.shortDescription }}
      </div>
      <base-link v-if="data.customsExternalUrl" class="body-4 relative underlined" :to="data.customsExternalUrl">
        <span class="absolute-0" />
        {{ $t.customize }}
      </base-link>
      <span v-else-if="colorsCount" class="body-4 flex text-grey-30">
        {{ colorsCount > 15 ? '15+' : colorsCount }} {{ $pluralize($t.colors, colorsCount).toLowerCase() }}
      </span>
      <product-pricing
        class="body-4"
        compact-omnibus
        :currency="data.currency"
        data-test-id="product-card-pricing"
        :price="productPrice"
        :show-discount-percentage="showDiscountPercentage"
        :show-original-price-for-omnibus="false"
      />
    </div>
    <base-button
      v-if="!isComingSoon && !isRobisOnly"
      :aria-label="$t.add"
      class="group absolute right-0 top-0 z-3"
      data-test-id="product-card-add"
      @click="openQuickshop"
    >
      <span class="m-1 block rounded-sm p-1 duration lg:m-2 group-hover:bg-white/90">
        <vf-icon :height="{ sm: '20', lg: '24' }" name="plus" :width="{ sm: '20', lg: '24' }" />
      </span>
    </base-button>

    <panel-quickshop v-slot="props" class-wrapper="!p-0" :size="{ sm: 'sm', lg: 'full' }" :title="$t.size">
      <dialog-quickshop v-bind="props" :product-id="swatchId" />
    </panel-quickshop>
  </div>
</template>

<script lang="ts" setup>
import type { Breadcrumb, ColorOption } from '#types/product'
import type { ProductClickAction } from '#types/gtm'
import type { Product, ProductCardExtended } from '#root/api/clients/product/data-contracts'

const {
  breadcrumbs,
  imageFetchPriority: fetchpriority,
  product,
  storeFilterState,
  trackingCategory,
} = withDefaults(
  defineProps<{
    /**
     * Page breadcrumbs from the product list
     */
    breadcrumbs?: Breadcrumb[]
    /**
     * Controls lazy load property of the default product image
     */
    lazy?: boolean
    /**
     * Product data
     */
    product: Product | ProductCardExtended
    /**
     * Tracking category for the click and details analytics events
     */
    trackingCategory?: string
    /**
     * Sets displaying the discount amount as percentage
     */
    showDiscountPercentage?: boolean
    /**
     * The id of the selected pickup store in the filters of the parent product list, if any
     */
    storeFilterState?: string
    /**
     * Set fetchpriority for the first product image
     */
    imageFetchPriority?: 'low' | 'high' | 'auto'
  }>(),
  {
    breadcrumbs: () => ([])
  }
)

const { $feature, $gtm } = useNuxtApp()
const origin = useOrigin()
const route = useRoute()
const router = useRouter()

const PanelQuickshop = createPanel('quickshop')

/*
 Some products can be marketed by Style (NF0A3XEO) or Style Color (NF0A3XEOLE4).
 When marketed by style we need to find the default color from the color attributes.
*/
const isStyle = PRODUCT_STYLE_ID_REGEX.test(product?.id)
const productUrl = ref(product.url)
const defaultColor = (isStyle ? getDefaultColorOption(product.attributes, product.id) || {} : {}) as ColorOption
const activeProduct = ref(defaultColor.id || product.id)
const swatchId = ref(defaultColor.id || product.id)

const { data } = useApi().products.details(activeProduct, {}, {
  immediate: false,
  key: `productCard-${activeProduct.value}`,
  default: () => ({
    ...product,
    url: defaultColor.url || productUrl.value,
    eyebrow: defaultColor.eyebrow || product.eyebrow,
    badge: defaultColor.badge || product.badge
  }),
  transform: (input) => ({
    ...input,
    eyebrow: input.eyebrow || product.eyebrow,
    badge: input.badge || product.badge
  })
}) as { data: Ref<Product>, pending: Ref<boolean> }

const categoryURL = breadcrumbs[breadcrumbs.length - 1]?.url
const colorsCount = 'availableColorsCount' in product ? product.availableColorsCount : 0
const url = computed(() => {
  if (data.value?.url && storeFilterState) {
    const baseUrl = `${data.value.url.startsWith('/') ? origin : ''}${data.value.url}`
    const parsedUrl = new URL(baseUrl)

    parsedUrl.searchParams.append('storeFilter', storeFilterState)

    return `${parsedUrl.pathname}${parsedUrl.search}`
  }

  return data.value?.url
})

const isComingSoon = computed(() => $feature.showComingSoonOnQuickshop && getIsComingSoonProduct(data.value))
const isRobisOnly = computed(() => $feature.allowProductReservationOnPDP && data.value?.isRobisOnly)

const productPrice = computed(() => {
  if (!$feature.enablePlpSwatchDetails || data.value.displayPriceRange) return data.value.price

  const swatches = data.value?.attributes.find(({ type }) => type === 'color')?.options as ColorOption[]
  const colorSwatch = swatches?.find(({ id }) => id === swatchId.value) as ColorOption
  return colorSwatch?.price || data.value.price
})

const handleGtmEvent = (payload, action: ProductClickAction) =>
  $gtm.push('product.onProductClick', payload, {
    action,
    breadcrumbs,
    category: trackingCategory,
    searchTerm: route.query.q?.toString()
  })

const navigateToPDP = (e?: PointerEvent) => {
  handleGtmEvent(data.value, 'Navigate To PDP')

  const serializedBreadcrumbs = serializeAnalyticsBreadcrumbs(breadcrumbs)
  const { path, query = {} } = getRouteFromUrl(url.value)

  const state = {
    breadcrumbs: serializedBreadcrumbs,
    category: trackingCategory,
    searchTerm: route.query.q,
    categoryURL
  }

  if (e?.metaKey || e?.ctrlKey) {
    localStorage.setItem(`from_plp_${data.value.id}`, JSON.stringify(state))
  }
  else {
    e?.preventDefault()
    router.push({ path, query, state })
  }
}

const openQuickshop = () => {
  PanelQuickshop.open({
    product: data.value,
    categoryURL,
    ...(storeFilterState && {
      gtmConfig: {
        shippingMethod: 'BOPIS_PLP_SD',
        shippingStoreId: storeFilterState,
        shippingWindow: '24Hrs'
      }
    })
  })
  handleGtmEvent(data.value, 'Open Quick Shop')
}
</script>
