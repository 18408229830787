<template>
  <div class="relative">
    <base-picture
      :alt="items?.[0]?.alt || `${name} - 1`"
      class="w-full cursor-pointer"
      data-test-id="product-card-pic"
      :height="mediaSize.height"
      :lazy
      :props-img="{ class: 'w-a', fetchpriority }"
      :src="getImageTransformations(
        items?.[0]?.src, { ...mediaSize, ...mediaTransformations },
      ) || defaultImageUrl"
      :width="mediaSize.width"
      @click="$emit('click-gallery-item')"
      @mouseenter="showCarousel = $viewport.lg"
    />
    <transition v-if="items?.[1]?.src" name="fade">
      <template v-if="showSecondaryMedia || showCarousel">
        <lazy-product-card-carousel
          v-if="$feature.enableProductCardGalleryCarousel"
          :items
          :lazy
          :name
          style="position: absolute; inset: 0"
          @click-gallery-item="$emit('click-gallery-item')"
        />
        <component
          :is="items[1].type === 'image' ? BasePicture : BaseVideo"
          v-else
          :alt="items[1].alt || `${name} - 2`"
          autoplay
          class="absolute-0 full"
          :class="{ 'bg-black': items[1].type !== 'image' }"
          data-test-id="product-card-pic-hover"
          fit="contain"
          :height="mediaSize.height"
          :lazy
          loop
          :src="items[1].type === 'image'
            ? getImageTransformations(items[1].src, { ...mediaSize, ...mediaTransformations })
            : items[1].src"
          :width="mediaSize.width"
        />
      </template>
    </transition>
    <slot name="badge" />
  </div>
</template>

<script lang="ts" setup>
import { BasePicture, BaseVideo } from '#components'
import type { ProductGalleryExtended } from '#root/api/clients/product/data-contracts'
import type { ImgHTMLAttributes } from '#types/components/base/picture'

defineProps<{
  items?: ProductGalleryExtended
  lazy?: boolean
  // product Name, used as alt fallback
  name: string
  showSecondaryMedia?: boolean
}>()

defineEmits<{
  'click-gallery-item': []
}>()

const { card: { mediaSize, mediaTransformations }, defaultImageUrl } = useAppConfig().components.product
const { fetchpriority } = useAttrs() as ImgHTMLAttributes

const showCarousel = ref(false)
</script>
